$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

$btn-primary-color:         #fff;    
$btn-primary-bg:            #219EE8;
$btn-primary-border:        #219EE8;

$screen-md:                  992px;
$screen-md-min:              $screen-md;
$grid-float-breakpoint:     $screen-md-min;
$line-height-base: 1.428571429;